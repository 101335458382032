<template>
  <div class="delete-address-modal" :class="type" data-t="delete-address-modal">
    <div class="header">
      <h2>{{ t('payments.addressBook.deleteAddress') }}</h2>
      <StIcon
        v-if="type === 'mobile'"
        name="cross-large"
        class="close-modal"
        data-t="close-modal-button-kh8n"
        @click="handleClose"
      />
    </div>
    <div v-if="address" class="content">
      <div class="title">
        {{ address.name }}
      </div>
      <div class="rows">
        <div class="row">
          <div class="left-side">{{ t('payments.addressBook.currency') }}</div>
          <div class="right-side">
            <StIcon
              :size="type === 'desktop' ? 24 : 16"
              :name="address.currencyIcon"
            />
            {{ address.currencyCode }}
          </div>
        </div>
        <div class="row">
          <div class="left-side">{{ t('payments.addressBook.network') }}</div>
          <div class="right-side">
            <div class="network">
              {{ address.networkDescription }}
            </div>
            {{ address.networkName }}
          </div>
        </div>
        <div class="row">
          <div class="left-side">
            {{ t('payments.addressBook.walletAddress') }}
          </div>
          <div class="right-side">
            {{ address.address }}
          </div>
        </div>
      </div>
    </div>
    <StButton
      :label="t('payments.addressBook.delete')"
      :loading="deleteStatus === 'pending'"
      data-t="delete-address-button-bdvz"
      @click="deleteAddressClicked"
    />
  </div>
</template>

<script setup lang="ts">
import { useAddressesStore } from '../../stores/useAddressesStore'

const props = withDefaults(
  defineProps<{ id: string; type: 'desktop' | 'mobile' }>(),
  {
    type: 'desktop',
  },
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()

const addressesStore = useAddressesStore()
const { deleteAddress, refreshAddresses } = addressesStore
const { addresses, deleteStatus } = storeToRefs(addressesStore)

const address = computed(() =>
  addresses.value.find((item) => item.id === Number(props.id)),
)

function handleClose() {
  emit('close')
}

async function deleteAddressClicked() {
  if (!address.value) return

  await deleteAddress(address.value.id)
  await refreshAddresses()
  handleClose()
}

const router = useRouter()

watch(deleteStatus, (status) => {
  if (status === 'success') {
    router.push({})
  }
})
</script>

<style scoped>
.delete-address-modal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  width: 596px;
  padding: var(--spacing-300);
}

h2 {
  margin: 0;
  padding: 0;
}

.content {
  display: flex;
  flex-direction: column;

  margin-bottom: var(--spacing-100);
  padding: var(--spacing-150);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.title {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-lg-semibold);
}

.rows {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.row {
  display: flex;
  justify-content: space-between;
}

.left-side {
  padding-right: var(--spacing-200);
  font: var(--desktop-text-md-medium);
  color: var(--palette-light-600);
  white-space: nowrap;
}

.right-side {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  font: var(--desktop-text-md-semibold);
  word-break: break-all;

  .network {
    padding: var(--spacing-025) var(--spacing-075);

    font: var(--desktop-text-xs-semibold);
    color: var(--palette-light-600);

    background-color: var(--background-tertiary);
    border-radius: var(--border-radius-full);
  }
}

.delete-address-modal.mobile {
  position: relative;

  gap: var(--spacing-100);

  width: 100%;
  min-width: auto;
  padding: 0 var(--spacing-200) var(--spacing-100);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-100);
  }

  h2 {
    font: var(--mobile-title-2-semibold);
  }

  .title {
    margin-bottom: 0;
    padding: var(--spacing-150) var(--spacing-200);
    font: var(--mobile-text-semibold);
    border-bottom: 1px solid var(--background-base);
  }

  .content {
    padding: 0;
  }

  .rows {
    padding: var(--spacing-200) 0;
  }

  .row {
    padding: 0 var(--spacing-200);
  }

  .left-side {
    padding-right: var(--spacing-100);
    font: var(--mobile-text-regular);
  }

  .right-side {
    gap: var(--spacing-050);
    font: var(--mobile-text-regular);
    text-align: right;
    word-break: break-all;
  }

  .network {
    font: var(--mobile-caption-2-medium);
  }
}
</style>
